
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDemandOvertime from '@/mixins/mixinDemandOvertime';
import dialog from "@/vuex/dialog";

@Component({
  components: {
  }
})
export default class Foot extends Mixins(mixinDemandOvertime) {
  get all_index() {
    return this.searched_sorted_demand_overtimes.length;
  }
  get start_index() {
    return this.disp_num * (this.page - 1) + 1;
  }
  get end_index() {
    return Math.min(this.all_index, this.disp_num * this.page);
  }
  get max_page() {
    return Math.ceil(this.all_index / this.disp_num);
  }
  get page_num_list() {
    var start_page = Math.max(1, this.page - 2);
    if (this.page > this.max_page - 2)
      start_page = Math.max(1, this.max_page - 4)
    var end_page = Math.min(this.max_page, this.page + 2);
    if (this.page < 3)
      end_page = Math.min(this.max_page, 5);
    var page_num_list = [];
    for (var i = start_page; i <= end_page; i++) {
      page_num_list.push(i);
    }
    return page_num_list;
  }
}
