
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDemandOvertime from '@/mixins/mixinDemandOvertime';
import CommonProgress from "@/components/common/Progress.vue";
import CommonButton from "@/components/common/Button.vue";
import DemandovertimePagerHead from "@/components/demandovertime/pager/Head.vue";
import DemandovertimeTable from "@/components/demandovertime/Table.vue";
import DemandovertimePagerFoot from "@/components/demandovertime/pager/Foot.vue";
import DemandovertimeDialogAdd from "@/components/demandovertime/dialog/Add.vue";

@Component({
  components: {
    CommonProgress,
    CommonButton,
    DemandovertimePagerHead,
    DemandovertimeTable,
    DemandovertimePagerFoot,
    DemandovertimeDialogAdd,
  }
})
export default class Main extends Mixins(mixinDemandOvertime) {
  add_demand_overtime() {
    this.tmp_demand_overtime = {
      branch_id: this.s_branch_id,
      kind: 0,
      date: null,
      started_at: null,
      ended_at: null,
      shift_shift_id: null,
      comment: null,
    };
    this.m.setIsOpenAddDemandOvertimeDialog(true);
  }

  get tmp_demand_overtime() {
    return this.m.demand_overtime;
  }
  set tmp_demand_overtime(val) {
    this.m.setDemandOvertime({demand_overtime: val});
  }
}
