
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";
import shiftly from "@/vuex/demand_overtime/shiftly";
import mixinDemandOvertime from '@/mixins/mixinDemandOvertime';
import * as util from '@/util';
import UtilPopup from "@/components/util/Popup.vue";
import CommonSelect from "@/components/common/Select.vue";
import CommonDate from "@/components/common/Date.vue";
import CommonProgress from "@/components/common/Progress.vue";
import CommonTime from "@/components/common/Time.vue";
import CommonText from "@/components/common/Text.vue";

@Component({
  components: {
    UtilPopup,
    CommonSelect,
    CommonDate,
    CommonProgress,
    CommonTime,
    CommonText,
  }
})
export default class Main extends Mixins(mixinDemandOvertime) {
  get is_display() {
    return this.m.is_open_add_demand_overtime_dialog;
  }
  set is_display(val: boolean) {
    this.m.setIsOpenAddDemandOvertimeDialog(val);
  }

  get tmp_demand_overtime() {
    return this.m.demand_overtime;
  }
  set tmp_demand_overtime(val) {
    this.m.setDemandOvertime({demand_overtime: val});
  }

  create_demand_overtime() {
    this.m.createDemandOvertime({is_prev_overdemand: this.global.is_prev_overdemand});
  }

  timespan_filter(shift_shift) {
    return util.formatTimeint(shift_shift.started_at)+"〜"+util.formatTimeint(shift_shift.ended_at);
  }

  on_change_demand_overtime() {
    Vue.set(this.tmp_demand_overtime, 'shift_shift_id', null);
    Vue.set(this.tmp_demand_overtime, 'started_at', null);
    Vue.set(this.tmp_demand_overtime, 'ended_at', null);
  }

  on_change_shift_shift(shift_shift_id) {
    if (this.tmp_demand_overtime.kind == 0) {//残業
      Vue.set(this.tmp_demand_overtime, 'started_at', this.shift_shift_hash[shift_shift_id].ended_at);
      Vue.set(this.tmp_demand_overtime, 'ended_at', null);
    } else if (this.tmp_demand_overtime.kind == 1) {//早出
      Vue.set(this.tmp_demand_overtime, 'started_at', null);
      Vue.set(this.tmp_demand_overtime, 'ended_at', this.shift_shift_hash[shift_shift_id].started_at);
    }
  }

  get demand_overtime_branch_id() {
    return this.tmp_demand_overtime.branch_id;
  }
  get demand_overtime_date() {
    return this.tmp_demand_overtime.date;
  }
  get demand_overtime_kind() {
    return this.tmp_demand_overtime.kind;
  }


  //vuexの操作 シフトのリアルタイム取得
  get is_set_shiftly(): boolean {
    return this.tmp_demand_overtime.branch_id !== null
      && this.tmp_demand_overtime.kind !== null
      && this.tmp_demand_overtime.kind != 2
      && this.tmp_demand_overtime.date !== null
      && shiftly.data[this.tmp_demand_overtime.date]
      && shiftly.data[this.tmp_demand_overtime.date][this.tmp_demand_overtime.branch_id]
      && shiftly.data[this.tmp_demand_overtime.date][this.tmp_demand_overtime.branch_id].is_set;
  }
  get is_valid_shiftly(): boolean {
    return this.tmp_demand_overtime.branch_id !== null
      && this.tmp_demand_overtime.kind !== null
      && this.tmp_demand_overtime.kind != 2
      && this.tmp_demand_overtime.date !== null
      && shiftly.data[this.tmp_demand_overtime.date]
      && shiftly.data[this.tmp_demand_overtime.date][this.tmp_demand_overtime.branch_id]
      && shiftly.data[this.tmp_demand_overtime.date][this.tmp_demand_overtime.branch_id].is_valid;
  }
  async get_shiftly() {
    if (
      this.tmp_demand_overtime.branch_id !== null
      && this.tmp_demand_overtime.kind !== null
      && this.tmp_demand_overtime.kind != 2
      && this.tmp_demand_overtime.date !== null
    ) {
      await shiftly.fetch({date: this.tmp_demand_overtime.date, branch_id: this.tmp_demand_overtime.branch_id});
    }
  }
  get shiftly() {
    if (!this.is_set_shiftly || !this.is_valid_shiftly) this.get_shiftly();
    if (this.is_set_shiftly) 
      return shiftly.data[this.tmp_demand_overtime.date][this.tmp_demand_overtime.branch_id];
  }
  get shift_shifts() {
    return this.shiftly.shift_shifts;
  }
  get shift_shift_hash() {
    return this.shiftly.shift_shift_hash;
  }
}
