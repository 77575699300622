
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDemandOvertime from '@/mixins/mixinDemandOvertime';
import CommonProgress from "@/components/common/Progress.vue";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonProgress,
    CommonButton,
  }
})
export default class Row extends Mixins(mixinDemandOvertime) {
  @Prop() demand_overtime;

  delete_demand_overtime(demand_overtime_id) {
    this.m.deleteDemandOvertime({demand_overtime_id});
  }
}
