
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import mixinDemandOvertime from '@/mixins/mixinDemandOvertime';
import DemandovertimeSpmain from "@/components/demandovertime/Spmain.vue";
import DemandovertimeMain from "@/components/demandovertime/Main.vue";

@Component({
  components: {
    DemandovertimeSpmain,
    DemandovertimeMain,
  }
})
export default class DemandOvertime extends Mixins(mixinDemandOvertime) {

}
