
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDemandOvertime from '@/mixins/mixinDemandOvertime';
import dialog from "@/vuex/dialog";
import DemandovertimeRow from "@/components/demandovertime/Row.vue";

@Component({
  components: {
    DemandovertimeRow,
  }
})
export default class Table extends Mixins(mixinDemandOvertime) {
}
